.mdxeditor {
    height: 100%;
}

.no-auto-expand .mdxeditor {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.no-auto-expand .mdxeditor-diff-source-wrapper {
    flex-grow: 1;
    height: 100%;
    position: relative;
    overflow-y: auto;
}

div.mdxeditor-popup-container {
    z-index: 81 !important;
}

div[data-radix-popper-content-wrapper] {
    z-index: 82 !important;
}

._contentEditable_uazmk_379 {
    padding: 10px;
}

/*----------------------- Toolbar ------------------*/

.mdxeditor-toolbar {
    position: relative;

    border-bottom: 1px solid;
    border-color: #CFD6E5;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    overflow-x: unset;

    padding: 3px;
    background-color: transparent !important;
    gap: 0px;
}

.error-state .mdxeditor-toolbar {
    border-color: #E95B69;
}

.dark-border .mdxeditor-toolbar {
    border-color: #5E6470;
}

.mdxeditor-toolbar > div {
    margin: 0px !important;
}

.mdxeditor-toolbar div[role=separator] {
    margin: 0 8px !important;
}

.mdxeditor-toolbar .mdxeditor-toolbar {
    border-radius: 8px;
}

.mdxeditor-toolbar button {
    padding: 6px;
    border-radius: 8px;

    cursor: pointer;
}

.mdxeditor-toolbar button svg {
    max-height: 18px;
    max-width: 18px;
}

/*----------------------Markdown Styling----------------------*/

.mdxeditor-root-contenteditable {
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 150%;

    color: #1F2125;
}

.mdxeditor-root-contenteditable h1, .mdxeditor-root-contenteditable h2, .mdxeditor-root-contenteditable h3, .mdxeditor-root-contenteditable h4, .mdxeditor-root-contenteditable h5, .mdxeditor-root-contenteditable h6 {
    font-family: InterBold, sans-serif;
    font-weight: 700;

    border: none;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-bottom: 0;
}

._contentEditable_uazmk_379 > *:first-child {
    margin-top: 0;
}

.mdxeditor-root-contenteditable h1 {
    font-size: 20px;
}

.mdxeditor-root-contenteditable h2 {
    font-size: 16px;
}

.mdxeditor-root-contenteditable h3 {
    font-size: 14px;
}

.mdxeditor-root-contenteditable ._placeholder_uazmk_1084 {
    color: #5E6470;
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 150%;
}

.mdxeditor-root-contenteditable ._placeholder_uazmk_1084 > p {
    font-size: 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mdxeditor-root-contenteditable p {
    font-size: 14px;
    margin-bottom: 10px;
}

.mdxeditor-root-contenteditable p:last-child {
    margin-bottom: 0;
}

.mdxeditor-root-contenteditable a {
    font-family: inherit;
    font-weight: 700;

    color: #754DCF;
    text-decoration-line: underline;
    text-underline-offset: 2px;
    cursor: pointer;
}

.mdxeditor-root-contenteditable ._underline_1tncs_18 {
    text-underline-offset: 2px;
}

.mdxeditor-root-contenteditable ul ul, .mdxeditor-root-contenteditable ul ol, .mdxeditor-root-contenteditable ol ul, .mdxeditor-root-contenteditable ol ol {
    padding-left: 24px;

    margin-top: 4px;
    margin-bottom: 4px;
}

.mdxeditor-root-contenteditable hr {
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;

    background-color: #CFD6E5;
    border: none;
}

.mdxeditor-root-contenteditable ol {
    list-style-type: decimal !important;
    list-style-position: inside;

    margin-bottom: 10px;
}

.mdxeditor-root-contenteditable ul {
    list-style-type: disc;
    list-style-position: inside;

    margin-bottom: 10px;
}

.mdxeditor-root-contenteditable li {
    margin-top: 0;
    margin-bottom: 0;
}

.mdxeditor-root-contenteditable ul > li[role="checkbox"] {
    margin-left: 0px;

    &::before {
        box-shadow: none;
    }
}

._listItemChecked_1tncs_73::before {
    border-color: #754DCF;
    background-color: #754DCF;
}

/*---------------------- Styling of code blocks ---------------------*/

.mdxeditor-root-contenteditable ._codeMirrorWrapper_uazmk_391 {
    background-color: #F0F5FE;
    margin-bottom: 10px;
    margin-top: 10px;
}

.custom-markdown-editor .mdxeditor-root-contenteditable ._codeMirrorWrapper_uazmk_391 {
    background-color: #FFFFFF;
}


/* Remove code line numbers */
.mdxeditor-root-contenteditable .cm-gutters {
    display: none;
}

.mdxeditor-root-contenteditable .cm-gutters .cm-gutterElement {
    display: none;
}

.mdxeditor-root-contenteditable .cm-content {
    font-size: 12px;

    background-color: #F0F5FE !important;
}

.custom-markdown-editor .mdxeditor-root-contenteditable .cm-content {
    background-color: #FFFFFF !important;
}

.mdxeditor-root-contenteditable .cm-line::selection {
    background-color: #ACCEF7 !important;
}

.mdxeditor-root-contenteditable .cm-line {
    font-family: Inter, sans-serif;
    font-size: 12px;
    line-height: 150%;
}

.mdxeditor-root-contenteditable .cm-activeLine {
    background-color: transparent;
}

/* Remove delete button and language selector */
.mdxeditor-root-contenteditable ._codeMirrorToolbar_uazmk_408 {
    display: none;
}

/*---------------------- Styling of error block ---------------------*/
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.mdxeditor ._markdownParseError_uazmk_1211 {
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out 1s forwards;
}

/*-------------------------- Tables ---------------------------*/
.mdxeditor tbody tr:nth-child(2n+3) {
    background-color: #f6f8fa;
}

.mdxeditor tbody td:first-child {
    background-color: white;
}

.mdxeditor table colgroup col:not(:first-child):not(:last-child) {
    min-width: 100px;
}

.mdxeditor ._addColumnButton_uazmk_746 {
    padding-left: 2px;
    padding-right: 2px;
}

.mdxeditor div:has(> table) {
    overflow-x: auto;
}