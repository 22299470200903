@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

@font-face {
    font-family: 'ClashDisplaySemibold';
    src: url('../public/fonts/ClashDisplay-Semibold.otf');
}

@font-face {
    font-family: 'ClashDisplayLight';
    src: url('../public/fonts/ClashDisplay-Regular.otf');
}

@font-face {
    font-family: 'InterRegular';
    src: url('../public/fonts/Inter-Regular.ttf');
}

@font-face {
    font-family: 'InterBold';
    src: url('../public/fonts/Inter-Bold.ttf');
}

@font-face {
    font-family: 'InterMedium';
    src: url('../public/fonts/Inter-Medium.ttf');
}

html {
    scrollbar-gutter: stable;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursorResize {
    cursor: url('../public/images/horizontal-resize-cursor.png') 17 0, col-resize;
}

.no-scroll {
    overflow: hidden;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #cfd0d1 transparent;
    -ms-overflow-style: none;
}

*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-thumb {
    background-color: #cfd0d1;
    border-radius: 50px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

