/*----------------------Markdown Styling----------------------*/

.w-md-editor {
    border-radius: 8px;
    --md-editor-box-shadow-color: #CFD6E5;
}

.darkBorderColor .w-md-editor {
    --md-editor-box-shadow-color: #5E6470;
}

.disabledState .w-md-editor textarea {
    pointer-events: none;
}

.w-md-editor .w-md-editor-text-input::placeholder {
    color: #5E6470;
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 150%;
}

.disabledState .w-md-editor .w-md-editor-preview, .disabledState .w-md-editor .wmde-markdown, .disabledState .w-md-editor .w-md-editor-area, .disabledState .w-md-editor {
    background-color: #F3F5F8;
}

.errorState .w-md-editor {
    --md-editor-box-shadow-color: #E95B69;
}

.wmde-markdown-color {
    color: inherit;
    background-color: inherit;
}

.wmde-markdown {
    max-width: 100%;

    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 150%;
}

.wmde-markdown h1, .wmde-markdown h2, .wmde-markdown h3, .wmde-markdown h4, .wmde-markdown h5, .wmde-markdown h6 {
    font-family: InterBold, sans-serif;
    font-weight: 700;

    border: none;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-bottom: 0;
}

.wmde-markdown h1 {
    font-size: 20px;
}

.wmde-markdown h2 {
    font-size: 16px;
}

.wmde-markdown h3 {
    font-size: 14px;
}

.wmde-markdown h1 > a > .octicon, .wmde-markdown h2 > a > .octicon, .wmde-markdown h3 > a > .octicon {
    display: none;
}

.wmde-markdown p {
    margin-top: 10px;
    margin-bottom: 10px;
}

.wmde-markdown strong {
    font-weight: 700;
}

.wmde-markdown a {
    font-family: inherit;
    font-weight: 700;

    color: #754DCF;
    text-decoration-line: underline;
}

.wmde-markdown ol {
    list-style-type: decimal;
    list-style-position: outside;

    padding-left: 28px;
    margin-bottom: 10px;
}

.wmde-markdown ul {
    list-style-type: disc;
    list-style-position: outside;

    padding-left: 28px;
    margin-bottom: 10px;
}

.wmde-markdown ol ol, .wmde-markdown ul ul, .wmde-markdown ul ol, .wmde-markdown ol ul {
    margin-top: 4px;
    margin-bottom: 4px;
}

.wmde-markdown li + li {
    margin-top: 0;
}

.wmde-markdown li > p {
    margin-top: 10px;
    margin-bottom: 10px
}

.wmde-markdown hr {
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;

    background-color: #CFD6E5;
    border: none;
}

.wmde-markdown pre > code {
    font-family: Inter, sans-serif;
    font-size: 12px;
    line-height: 150%;

    border-radius: 8px;
    background-color: #F0F5FE;
}

.previewOnly > .wmde-markdown .anchor {
    display: none;
}

.wmde-markdown pre.language-bash {
    width: 100%;
    max-width: 100%;

    overflow-x: auto;
}

.wmde-markdown table {
    overflow-x: auto;
}

/*----------------------Height Styles---------------------*/

.autoExpand .w-md-editor-content > .w-md-editor-preview {
    position: static;
}

.noAutoExpand .w-md-editor {
    display: flex;
    flex-direction: column;
}

.noAutoExpand .w-md-editor-content {
    flex-grow: 1;
    border-radius: 0;
}

.noAutoExpand .w-md-editor-text {
    min-height: 40px !important;
}

.autoExpand .w-md-editor-text {
    min-height: 80px !important;
}

.noAutoExpand .w-md-editor-content > .w-md-editor-area {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    overflow: auto;
    border-radius: unset;
}

.noAutoExpand .previewOnly {
    position: relative;
}

.previewOnly > .wmde-markdown {
    padding: 20px;
}

.previewOnly.withoutPadding > .wmde-markdown {
    padding: 0;
}

.noAutoExpand .previewOnly > .wmde-markdown {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
}

/*-----------------------ToolBar------------------------*/

.disabledState .w-md-editor-toolbar {
    background-color: #F3F5F8;
}

.w-md-editor-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.w-md-editor-toolbar ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2px;
}

.w-md-editor-toolbar li {
    height: 28px;

    position: relative;
    padding: 3px;
}

.w-md-editor-toolbar li > button {
    min-width: 22px;
    height: 22px;
    padding: 3px;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.w-md-editor-toolbar .w-md-editor-toolbar-divider {
    width: 1px;
    height: 20px;
    padding-left: 0;
    padding-right: 0;

    background-color: #CFD6E5;
}

.w-md-editor-toolbar-child .w-md-editor-toolbar ul {
    display: flex;
    flex-direction: column;
    align-items: start;

    width: 180px;
    padding: 8px;
}

.w-md-editor-toolbar-child .w-md-editor-toolbar li {
    width: 100%;
    height: auto;
}

.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li button {
    display: block;
    padding: 4px 8px;
}

.w-md-editor-toolbar-child .w-md-editor-toolbar {
    border-radius: 8px;
}

.w-md-editor-toolbar-child {
    position: absolute;
    top: calc(100% + 4px);
    left: -3px;

    border-radius: 8px;
}
