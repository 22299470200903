@import url('@radix-ui/colors/slate.css');

.linkDialogAnchor {
    position: fixed;
    background-color: highlight;
    z-index: -1;

    &[data-visible=true] {
        visibility: visible;
    }

    &[data-visible=false] {
        visibility: hidden;
    }
}

.linkDialogPopoverContent {
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));

    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px;

    border-radius: 8px;
    border: 1px solid #E2E8F0;
    background-color: white;
    font-size: 0.875rem;
}

.linkDialogPreviewAnchor {
    margin-right: 4px;

    display: flex;
    align-items: center;

    font-family: Inter, sans-serif;
    font-weight: 700;

    color: #754DCF;
    border: 1px solid transparent;
    text-decoration: none;


    & span {
        max-width: 14rem;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}


.tooltipContent {
    position: relative;
    z-index: 2;

    border-radius: 8px;
    padding: 4px 8px;
    font-size: 14px;
    background-color: #111827;
    color: #F8FAFC;

    & svg {
        fill: #111827;
    }
}

.popoverArrow {
    fill: #FFFFFF;
}

.actionButton {
    border: 0;
    all: unset;
    box-sizing: border-box;

    appearance: none;
    font-size: inherit;

    padding: 4px;
    color: #1F2125;
    border-radius: 8px;

    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s;

    & svg {
        display: block;
    }

    @media (hover: hover) {
        &:hover {
            background-color: #F1F5F9;
        }
    }

    &:active svg {
        transform: translate(1px, 1px);
    }

    &[data-state="on"],
    &:active {
        background-color: #F1F5F9;
        color: #F1F5F9;
    }
}

form.multiFieldForm {
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 8px;
}

.linkDialogEditForm {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    padding: 0;
}

.formField {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & label {
        font-family: InterBold, sans-serif, sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #1F2125;
    }
}

.textInput {
    all: unset;
    padding: 10px 10px;

    border-radius: 8px;
    border: 1px solid #5E6470;
    background-color: #FFFFFF;

    font-size: 14px;
    font-weight: 400;
    color: #1F2125;

    &::placeholder {
        color: #5E6470;
    }
}

.primaryButton, .secondaryButton {
    font-family: ClashDisplaySemibold, sans-serif;
    font-size: 14px;
    font-weight: 600;

    border-radius: 8px;
    padding: 8px 16px;

    &:disabled {
        background: #D1D5DB;
        border-color: #E0E7FF;
    }
}

.primaryButton {
    background-color: #754DCF;
    color: #FFFFFF;

    &:disabled {
        background-color: #EEEAF9;
        color: #C6B8E7;
    }
}

.secondaryButton {
    background-color: #F0F5FE;
    color: #33373D;

    &:disabled {
        color: #CFD6E5;
    }
}