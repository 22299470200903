.code-editor .cm-editor .cm-line {
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 150%;

    color: #1F2125;
}

.code-editor.disabled .cm-editor {
    background-color: #F3F5F8;
}

.code-editor.disabled .cm-editor .cm-activeLine {
    background-color: transparent;
}

/* --------------Left Side Panel Styling--------------- */
.code-editor .cm-editor .cm-gutters {
    min-width: 60px;
    display: flex;
    justify-content: end;

    background-color: #CFD6E5;
}

.code-editor .cm-gutters > .cm-gutter.cm-lineNumbers {
    font-family: Inter, sans-serif;
    font-size: 14px;

    color: #1F2125;
}

.code-editor .cm-gutters > .cm-gutter > .cm-activeLineGutter {
    background-color: transparent;
}

.code-editor .cm-gutters > .cm-gutter > .cm-gutterElement {
    color: #1F2125;
}

.code-editor .cm-gutter.cm-lineNumbers {
    min-height: var(--gutter-min-height);
}