.mermaid > svg {
    margin: 0;
    display: block;
}

.mermaid-regular-screen > svg {
    max-width: 100%;
    height: auto;
}

.mermaid-full-screen > svg {
    height: 100%;
    width: 100%;
    max-width: 100% !important;
}